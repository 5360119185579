exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compradores-js": () => import("./../../../src/pages/compradores.js" /* webpackChunkName: "component---src-pages-compradores-js" */),
  "component---src-pages-contactanos-js": () => import("./../../../src/pages/contactanos.js" /* webpackChunkName: "component---src-pages-contactanos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-privacidad-js": () => import("./../../../src/pages/informacion/privacidad.js" /* webpackChunkName: "component---src-pages-informacion-privacidad-js" */),
  "component---src-pages-informacion-terminos-y-condiciones-js": () => import("./../../../src/pages/informacion/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-informacion-terminos-y-condiciones-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

